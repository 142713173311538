import React from "react";
const Speedrun = ({}) => {
  return (
    <section>
      <header>
        <h2>Speedrun Page! (TBD)</h2>
      </header>
    </section>
  );
};

export default Speedrun;
